import React, { useEffect, useState } from "react";
import { Button, Flex, Skeleton } from "antd";
import { Row } from "antd";
import { Card } from "antd";
import { Link, useParams } from "react-router-dom";
import { ConventionsService } from "../../../services/conventions/conventions.service";
import { Convention } from "../../../domains/convention";

interface RouteParams {
  [key: string]: string | undefined;
}

export function Payments() {
  const [convention, setConvention] = useState<Convention>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { number: routeNumber } = useParams<RouteParams>();
  const number = routeNumber;
  const [error, setError] = useState<string>("");

  async function onFinish() {
    try {
      setIsSubmitting(true);
      const conventionsService = new ConventionsService();
      const result = await conventionsService.getConvention(number);

      if ("error" in result) {
        setError(result.error);
      } else if (result) {
        setConvention(result.convention);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    onFinish();
  }, []);

  async function handlePayment(paymentMethod: string) {
    try {
      setIsSubmitting(true);
      const conventionsService = new ConventionsService();
      console.log(number);
      const result = await conventionsService.postPayment(
        number,
        paymentMethod,
      );

      if ("error" in result) {
        setIsSubmitting(false);
      } else if (result) {
        window.location.href = result.url;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="content">
      {isSubmitting ? (
        <Skeleton active />
      ) : (
        <>
          <p className="custom-content">
            Le règlement de la prestation funéraire peut s’effectuer par :
          </p>
          <Row gutter={16}>
            <Card
              hoverable
              style={{
                width: 300,
                margin: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              cover={
                <img
                  alt="CB"
                  src="https://i.ibb.co/D5rPhGy/Image-Pasted-2024-03-04-at-15-16-31.png"
                  style={{ width: 150, margin: 10 }}
                />
              }
            >
              <Flex gap="small" wrap="wrap" style={{ marginTop: "auto" }}>
                <Button
                  size="large"
                  type="primary"
                  style={{ background: "#d53865" }}
                  className="custom-button"
                  onClick={() => handlePayment("cb")}
                >
                  En 1 fois
                </Button>
              </Flex>
              <p>Par carte bancaire</p>
            </Card>

            {convention && convention.total >= 50 && (
              <Card
                hoverable
                style={{
                  width: 400,
                  margin: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                cover={
                  <img
                    alt="alma"
                    src="https://www.datocms-assets.com/63692/1682715062-alma-logo-retool.png"
                    style={{ width: 150, margin: 10 }}
                  />
                }
              >
                <Flex gap="small" wrap="wrap" style={{ marginTop: "auto" }}>
                  <Button
                    size="large"
                    type="primary"
                    style={{ background: "#d53865" }}
                    className="custom-button"
                    onClick={() => handlePayment("alma_2")}
                  >
                    En 2 fois*
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    style={{ background: "#d53865" }}
                    className="custom-button"
                    onClick={() => handlePayment("alma_3")}
                  >
                    En 3 fois*
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    style={{ background: "#d53865" }}
                    className="custom-button"
                    onClick={() => handlePayment("alma_4")}
                  >
                    En 4 fois*
                  </Button>
                </Flex>
                <p>
                  <center>Par carte bancaire</center>
                </p>
              </Card>
            )}
          </Row>
          <Row justify="center" style={{ marginTop: "20px" }}>
            <p className="custom-content">
              *un crédit vous engage et doit être remboursé, vérifiez vos
              capacités de remboursement avant de vous engager. Pour plus de
              précisions consultez les{" "}
              <Link to="/terms" target="_blank" className="custom-a">
                CGV
              </Link>
            </p>
          </Row>
        </>
      )}
    </div>
  );
}
